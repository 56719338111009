body,
html,
#root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Roboto";
  -webkit-text-size-adjust: none;
  touch-action: pan-y; /*prevent user scaling*/
}

* {
  box-sizing: border-box;
}
